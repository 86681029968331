import Head from 'next/head'
import Link from 'next/link'
import {
  FormEvent,
  MouseEvent,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import Authentication from '~components/Layouts/Authentication'
import FormGroup from '~components/ui/Form/FormGroup'
import FormInput from '~components/ui/Form/FormInput'
import FormSelect from '~components/ui/Form/FormSelect'
import { UserContext } from '~context/UserContext'

import { useLoadTenantsForDomainQuery } from 'api/tenants-gql.generated'
import Alert from '../components/ui/Alert/Alert'
import Button from '../components/ui/Button/Button'

enum State {
  Idle,
  LoggingIn,
  LoggedIn,
  Errored,
}

export default function Login() {
  const [state, setState] = useState<State>(State.Idle)
  const [tenantId, setTenantId] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { data: tenantsData } = useLoadTenantsForDomainQuery()
  const tenants = useMemo(() => {
    return tenantsData?.tenantsForDomain ?? []
  }, [tenantsData])
  const userContext = useContext(UserContext)

  useEffect(() => {
    if (tenants.length > 0 && !tenantId) {
      setTenantId(tenants[0].id)
    }
  }, [tenants, tenantId])

  const signIn = async () => {
    setState(State.LoggingIn)

    if (!tenantId) {
      return
    }

    try {
      await userContext.login(email, password, tenantId)
      setState(State.LoggedIn)
    } catch (e) {
      setState(State.Errored)
      // Clear potentially stale auth cookies
      await fetch('/api/logout', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
      })
    }
  }

  const handleSubmit = (e: MouseEvent | FormEvent) => {
    e.preventDefault()
    signIn()
  }

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <h1 className="text-2xl font-semibold leading-none text-center mb-7">Sign in</h1>
      {tenants && (
        <form className="mb-12 space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
          {state === State.Errored && <Alert variant="danger" title="User not found" />}

          {tenants.length > 1 && tenantId && (
            <FormGroup htmlFor="current-tenant" label="Tenant">
              <FormSelect
                id="current-tenant"
                value={tenantId}
                onChange={(v) => setTenantId(v)}
                options={tenants.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              />
            </FormGroup>
          )}

          <FormGroup htmlFor="email" label="E-mail">
            <FormInput
              id="email"
              value={email}
              onChange={(v) => setEmail(v)}
              placeholder="Your e-mail"
              type="email"
              autoComplete="email"
              required
            />
          </FormGroup>

          <FormGroup htmlFor="password" label="Password">
            <FormInput
              id="password"
              value={password}
              onChange={(v) => setPassword(v)}
              placeholder="Your password"
              type="password"
              autoComplete="current-password"
              required
            />

            <Link
              href="/forgot-password"
              className="inline-block mt-2.5 text-xs leading-none text-gray-600 hover:underline hover:text-blue-600"
            >
              Forgot your password?
            </Link>
          </FormGroup>

          <Button type="submit" fullWidth disabled={!tenantId}>
            Sign in
          </Button>
        </form>
      )}
    </>
  )
}

Login.getLayout = function getLayout(page: ReactElement): ReactNode {
  return <Authentication>{page}</Authentication>
}
