import React, { ReactNode } from 'react'

import AppShellWordmark from '../ui/AppShell/AppShellWordmark'

export default function Authentication({ children }: { children: ReactNode }) {
  return (
    <div className="max-w-sm px-8 py-10 m-auto">
      <header className="flex items-center justify-center mb-10">
        <AppShellWordmark size={110} />
      </header>

      <main>{children}</main>
    </div>
  )
}
