import clsx from 'clsx'
import { ClassAttributes, forwardRef, InputHTMLAttributes } from 'react'
import { getInputVariantClasses, InputVariant } from './form.types'

export type FormInputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> &
  ClassAttributes<HTMLInputElement> & {
    onChange: (value: string, validity: ValidityState) => void
    placeholder?: string
    className?: string
    height?: string
    border?: string
    fullWidth?: boolean
    variant?: InputVariant
    rounded?: boolean
    styleOnFocus?: boolean
  }

export default forwardRef<HTMLInputElement, FormInputProps>(function FormInput(
  {
    onChange,
    placeholder,
    className,
    fullWidth,
    height = 'h-9',
    border = 'border',
    variant = 'default',
    rounded = true,
    styleOnFocus = true,
    ...props
  },
  ref
) {
  const variantClasses = getInputVariantClasses(variant, height, border, styleOnFocus, fullWidth)

  return (
    <input
      {...props}
      ref={ref}
      className={clsx(variantClasses, className, rounded && 'rounded-lg')}
      placeholder={placeholder}
      onChange={(e) => onChange(e.target.value, e.target.validity)}
    />
  )
})
