import clsx from 'clsx'

export type InputVariant = 'default' | 'danger' | 'success' | 'warning'

export const getInputVariantClasses = (
  variant: InputVariant,
  height: string,
  border: string,
  styleOnFocus: boolean,
  fullWidth?: boolean
) => {
  const baseFocus =
    'focus:outline-none focus:ring-2 focus:ring-blue-200 focus:opacity-100 focus:border-blue-600'

  const base = clsx(
    'block text-sm leading-none py-2 px-2.5 round placeholder-neutral-400 placeholder:italic',
    height,
    border,
    styleOnFocus ? baseFocus : 'focus:outline-none ',
    {
      'w-full': fullWidth || true,
    }
  )

  switch (variant) {
    case 'danger': {
      return clsx(base, 'border-red-400')
    }

    case 'success': {
      return clsx(base, 'border-green-400')
    }

    case 'warning': {
      return clsx(base, 'border-amber-400')
    }

    default: {
      return clsx(base, 'border-gray-200')
    }
  }
}
