import React from 'react'

export default function AppShellWordmark({ size }: { size: number }): JSX.Element {
  return (
    <svg
      fill="none"
      height={size / 2}
      viewBox="0 0 110 55"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m31.9774 18.451v6.9656l-2.3939-1.3832c-.0949-.0547-.1738-.1334-.2286-.2283-.0548-.0948-.0837-.2024-.0837-.312v-5.0421c.0002-.2374-.0622-.4707-.181-.6763s-.2897-.3762-.4955-.4946l-8.8649-5.1193c-.2056-.1185-.4387-.1809-.676-.1809s-.4704.0624-.6759.1809l-6.2408 3.5925c-.0483.026-.0885.0649-.116.1124s-.0412.1017-.0396.1566c-.0017.0553.0119.1101.0394.1582.0274.0481.0677.0876.1162.1143l6.7449 3.894c.0949.0547.1738.1334.2286.2283.0548.0948.0837.2024.0837.312v2.6531c0 .011-.0029.0218-.0084.0313s-.0134.0174-.023.0229c-.0095.0055-.0203.0084-.0313.0084s-.0218-.0029-.0314-.0084l-8.321-4.8021c-.46-.2641-.84213-.6449-1.10788-1.1039-.26576-.4591-.4057-.9801-.4057-1.5105 0-.5305.13994-1.0515.4057-1.5106.26575-.459.64788-.8398 1.10788-1.1039l6.2408-3.59256c.6172-.35407 1.3163-.54038 2.0278-.54038s1.4106.18631 2.0278.54038l8.8649 5.11926c.6182.3565 1.1318.8693 1.4893 1.4869s.5463 1.3184.5476 2.032z"
        fill="#1e0dff"
      />
      <path
        d="m23.614 26.0484c-.174-.1006-.3714-.1536-.5724-.1537-.201 0-.3985.0528-.5725.1533-.1741.1005-.3186.2451-.4191.4191-.1005.1741-.1533.3716-.1532.5726v15.6508c-.0001.0548.0142.1087.0415.1562.0274.0475.0667.087.1141.1145s.1013.042.1561.0421.1087-.0142.1562-.0415l6.2299-3.5973c.2055-.1191.3762-.29.4949-.4957s.1813-.439.1816-.6765v-6.4518c0-.011.003-.0218.0085-.0314.0055-.0095.0134-.0174.0229-.0229s.0204-.0084.0314-.0084.0218.0029.0313.0084l2.2997 1.3266c.0949.055.1737.134.2285.229s.0837.2027.0839.3124v4.6393c-.0014.7123-.1895 1.4117-.5456 2.0286-.3562.6168-.8679 1.1295-1.484 1.4867l-6.2299 3.5974c-.4666.2727-.9984.414-1.5388.4086-.5404-.0053-1.0693-.1569-1.5304-.4388-.4511-.2807-.8223-.673-1.0777-1.139-.2553-.466-.3863-.9899-.3803-1.5213v-15.5796c.0004-.6755.1784-1.339.5162-1.9239.3379-.5849.8236-1.0706 1.4085-1.4085.5849-.3378 1.2484-.5158 1.9238-.5162.6755-.0004 1.3391.1769 1.9244.5141l11.6627 6.734c.2058.1186.4391.181.6766.181.2374 0 .4707-.0624.6765-.181l6.2299-3.5973c.0476-.0274.0871-.0668.1146-.1143.0275-.0476.042-.1015.042-.1564s-.0145-.1089-.042-.1564-.067-.087-.1146-.1144l-9.7042-5.6016c-.0949-.055-.1737-.134-.2285-.229s-.0837-.2028-.0839-.3125v-2.653c0-.011.0029-.0219.0084-.0314s.0135-.0174.023-.0229.0203-.0084.0313-.0084.0218.0029.0314.0084l11.2756 6.5121c.4594.2643.841.645 1.1064 1.1038.2653.4588.4051.9795.4051 1.5095s-.1398 1.0507-.4051 1.5095c-.2654.4588-.647.8395-1.1064 1.1038l-6.2299 3.5985c-.6176.355-1.3174.5418-2.0296.5418-.7123 0-1.4121-.1868-2.0297-.5418z"
        fill="#1e0dff"
      />
      <g fill="#000">
        <path d="m58.5888 33.7424v-7.0909c0-.0566-.0225-.1109-.0625-.151-.04-.04-.0943-.0625-.1509-.0625h-2.0248v-2.2394c-.0004-.0553.0209-.1085.0592-.1484.0382-.0398.0906-.0632.1458-.0651 1.7969-.0482 2.1936-.7477 2.1936-2.2913v-1.3832c0-.0567.0225-.1111.0625-.1514.04-.0402.0943-.0629.151-.0633h2.5578c.0567.0004.111.0231.151.0633.04.0403.0624.0947.0624.1514v3.1704c0 .0281.0056.0558.0163.0817s.0264.0495.0463.0693c.0198.0198.0433.0355.0692.0462.0259.0108.0537.0163.0817.0163h3.2778c.0566 0 .1109.0225.1509.0625s.0625.0943.0625.1509v2.3179c0 .0566-.0225.1109-.0625.1509s-.0943.0625-.1509.0625h-3.2778c-.0566 0-.1109.0225-.1509.0625-.0401.0401-.0626.0944-.0626.151v6.2902c0 1.7583.7236 2.2117 2.7737 2.2117.2677 0 .5355 0 .896-.0157.0289-.0013.0578.0033.0849.0134s.0518.0256.0728.0456c.0209.0199.0376.0439.0491.0704.0114.0266.0174.0552.0175.0841v2.3009c-.0003.0533-.0203.1046-.0561.144-.0359.0394-.085.0641-.138.0695-.656.0639-1.1879.0868-1.698.0868-3.8675 0-5.147-1.4664-5.147-4.2112z" />
        <path d="m68.2906 17.4308h3.0149c.0567 0 .1111.0224.1513.0624.0403.04.063.0943.0633.151v2.7448c-.0003.0567-.023.111-.0633.151-.0402.04-.0946.0624-.1513.0624h-3.0149c-.0566 0-.1109-.0225-.1509-.0625-.0401-.04-.0626-.0943-.0626-.1509v-2.7448c0-.0566.0225-.1109.0626-.1509.04-.04.0943-.0625.1509-.0625zm.1592 6.2637h2.7182c.0566 0 .1109.0225.1509.0625.0401.04.0625.0943.0625.1509v13.5657c0 .0566-.0224.1109-.0625.151-.04.04-.0943.0625-.1509.0625h-2.7182c-.0566 0-.1109-.0225-.1509-.0625-.0401-.0401-.0626-.0944-.0626-.151v-13.5657c0-.0566.0225-.1109.0626-.1509.04-.04.0943-.0625.1509-.0625z" />
        <path d="m74.66 17.4308h2.717c.0566 0 .1109.0225.1509.0625s.0625.0943.0625.1509v19.8294c0 .0566-.0225.1109-.0625.151-.04.04-.0943.0625-.1509.0625h-2.717c-.0568 0-.1112-.0225-.1514-.0625s-.063-.0942-.0633-.151v-19.8294c.0003-.0567.0231-.111.0633-.151s.0946-.0624.1514-.0624z" />
        <path d="m80.7095 17.4308h2.717c.0568 0 .1112.0224.1514.0624s.063.0943.0633.151v19.8294c-.0003.0568-.0231.111-.0633.151s-.0946.0625-.1514.0625h-2.717c-.0566 0-.1109-.0225-.1509-.0625-.04-.0401-.0625-.0944-.0625-.151v-19.8294c0-.0566.0225-.1109.0625-.1509s.0943-.0625.1509-.0625z" />
        <path d="m85.6924 33.7666c0-1.3061.2412-2.3721 1.5195-3.1982 1.4133-.8804 2.934-1.4134 7.9713-1.4134h.6657c.0566 0 .1109-.0225.1509-.0625s.0625-.0943.0625-.1509v-.5294c0-1.5678-.7465-2.6121-3.4647-2.6121-2.7411 0-3.2006.978-3.3139 1.8668-.0065.0513-.0315.0986-.0704.1328-.0388.0342-.0889.053-.1407.0529h-2.653c-.0289.0002-.0576-.0055-.0842-.0168-.0266-.0112-.0507-.0278-.0707-.0487s-.0355-.0457-.0456-.0728c-.01-.0271-.0145-.0559-.013-.0848.1098-2.0501 1.0926-4.3414 6.4422-4.3414 4.0773 0 6.6098 1.4399 6.6098 6.0502v8.1293c0 .0566-.0225.1109-.0625.1509-.0401.0401-.0944.0626-.151.0626h-2.4227c-.0566 0-.1109-.0225-.151-.0626-.04-.04-.0625-.0943-.0625-.1509v-.7947l-.0265-.7453c-1.1722 1.2795-2.7459 2.0501-5.2519 2.0501-4.3438.0024-5.4376-2.161-5.4376-4.2111zm10.4218-1.0637v-1.1191c0-.0566-.0225-.1109-.0625-.1509-.0401-.0401-.0944-.0626-.151-.0626h-.8526c-3.9712 0-6.611.2412-6.611 2.3191 0 1.2252 1.1722 1.7583 3.4116 1.7583 3.3598 0 4.2655-1.8114 4.2655-2.7448z" />
      </g>
    </svg>
  )
}
